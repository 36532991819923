h1, h2, h3, h4, h5, h6 {
  line-height: 1;
  margin: 0;
  padding: 0;
}

body {
  background: #0D1217;
  font-family: "CircularStd";
}

#root {
  width: 100vw;
  height: 100vh;
}

.App {
  height: 100%;
  position: relative;
  width: 100%;
}

#background {
  position: absolute;
  overflow-y: hidden;
  width: 100%;
  z-index: 1;
}

#backgrounds {
  height: 100%;
  width: 100%;
  z-index: 1;

  img {
    height: 100%;
    position: absolute;
    left: 0;
    z-index: 1;

    @media all and (min-width: 960px) {
      width: 100%;
    }
  }

}

.title-container {
  padding: 38px 0 0;
  position: relative;
  text-align: center;
  width: 100%;
  z-index: 3;
}

.title-container h3 {
  background-color: #00000089;
  border-radius: 50px;
  color: white;
  display: inline-block;
  font-size: 14px;
  font-weight: bold;
  line-height: 24px;
  margin: 0 auto;
  padding: 0 31px 0 39px;
  letter-spacing: 8px;
  text-transform: uppercase;
}

.title-container h1 {
  font-family: "Maginia";
  font-size: 84px;
  font-weight: normal;
  letter-spacing: -4px;
  text-transform: none;

  @media all and (min-width: 960px) {
    font-size: 108px;
    line-height: 100px;
  }
}

.cryptogirlz {
  display: flex;
  flex-direction: row;
  height: 340px;
  position: relative;
  margin: 0 auto;
  max-width: 420px;
  z-index: 3;

  @media all and (min-width: 960px) {
    margin: 60px auto 0;
  }
}

#girl-1 {
  bottom: 0;
  height: 300px;
  left: -70px;
  position: absolute;
  z-index: 3;

  @media all and (min-width: 960px) {
    height: unset;
    left: -180px;
  }
}

#girl-2 {
  bottom: 0;
  height: 340px;
  position: absolute;
  left: calc(50% - (330px / 2));
  z-index: 4;

  @media all and (min-width: 960px) {
    height: 440px;
    left: calc(50% - (440px / 2));
  }
}

#girl-3 {
  bottom: 0;
  height: 300px;
  position: absolute;
  right: -70px;
  z-index: 3;

  @media all and (min-width: 960px) {
    height: unset;
    right: -193px;
  }
}

#wrapper {
  height: calc(100% - 500px);
  padding-top: 360px;
  position: relative;
  z-index: 2;

  @media all and (min-width: 960px) {
    height: calc(100% - 611px);
    padding-top: 350px;
  }
}

#shadow {
  background: linear-gradient(180deg, rgba(196, 196, 196, 0) 0%, rgba(13, 18, 23, 0) 0.01%, #0D1217 89.58%);
  height: 140px;
  width: 100%;
  z-index: 0;

  @media all and (min-width: 960px) {
    background: linear-gradient(180deg, rgba(13, 18, 23, 0) 0%, rgba(13, 18, 23, 0) 0.01%, #0D1217 78.12%);
    height: 261px;
  }
}

#container {
  height: 100%;
  position: relative;
}

#content-bg {
  background: #0D1217;
  height: 100%;
}

#content {
  box-sizing: border-box;
  color: #F2F5F8;
  height: 100%;
  margin-top: 120px;
  padding: 6px 34px 0;
  position: absolute;
  text-align: center;
  top: 0;
  width: 100%;

  p {
    line-height: 28px;
    margin: 0;
    position: relative;
    z-index: 2;
  }

  @media all and (min-width: 960px) {
    margin-top: 180px;
  }
}

.button {
  background: #5964F2;
  border-radius: 50px;
  border: none;
  color: #F2F5F8;
  cursor: pointer;
  font-family: "CircularStd";
  font-size: 18px;
  line-height: 30px;
  margin-top: 15px;
  outline: none;
  padding: 14px 20px;
  text-decoration: none;
  transition: 0.39s all ease;
  vertical-align: middle;

  @media all and (min-width: 414px) {
    padding: 14px 34px;
  }
}

.button:hover {
  background: #3742D0;
}

.button svg {
  display: inline-block;
  margin-bottom: -4px;
  margin-right: 4px;
}

.button .label {
  display: inline-block;
  font-weight: 500;
}

#socials-list {
  display: flex;
  flex-direction: row;
  justify-content: center;
  list-style: none;
  padding: 0;
  margin: 25px 0 0;
}

#socials-list li {
  margin-left: 5px;
}

#socials-list li a {
  padding: 8px;
}

#socials-list li a path {
  transition: 0.39s all ease;
}

#socials-list li a:hover path {
  color: white;
  fill: white;
}

#socials-list li:first-child {
  margin-left: 0px;
}

#partners {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  #nftcalendar-logo {
    height: 80px;

    @media all and (min-width: 960px) {
      height: 130px;
    }
  }

  #solana-logo {
    margin-left: 20px;
    height: 55px;
  }
}
