@font-face {
  font-family: "CircularStd";
  src: url("../../public/fonts/CircularStd/CircularStd-Medium/CircularStd-Medium.ttf");
  font-style: normal;
  font-weight: 500;
  font-display: swap;
}
@font-face {
  font-family: "CircularStd";
  src: url("../../public/fonts/CircularStd/CircularStd-Bold/CircularStd-Bold.ttf");
  font-style: normal;
  font-weight: 600;
  font-display: swap;
}
@font-face {
  font-family: "CircularStd";
  src: url("../../public/fonts/CircularStd/CircularStd-Black/CircularStd-Black.ttf");
  font-style: normal;
  font-weight: 900;
  font-display: swap;
}
@font-face {
  font-family: "CircularStd";
  src: url("../../public/fonts/CircularStd/CircularStd-Book/CircularStd-Book.ttf");
  font-style: normal;
  font-weight: 450;
  font-display: swap;
}
@font-face {
  font-family: "Maginia";
  src: url("../../public/fonts/Maginia/Maginia-Regular.woff");
  font-style: normal;
  font-weight: normal;
  font-display: swap;
}
